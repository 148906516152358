export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const INSPIRATION_REQUEST = "INSPIRATION_REQUEST";
export const INSPIRATION_SUCCESS = "INSPIRATION_SUCCESS";
export const INSPIRATION_ERROR = "INSPIRATION_ERROR";

export const EMAIL_REQUEST = "EMAIL_REQUEST";
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const EMAIL_ERROR = "EMAIL_ERROR";

export const OTP_REQUEST = "OTP_REQUEST";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_ERROR = "OTP_ERROR";

export const RESET_REQUEST = "RESET_REQUEST";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";

export const INSPIRATION_BY_ID_REQUEST = "INSPIRATION_BY_ID_REQUEST";
export const INSPIRATION_BY_ID_SUCCESS = "INSPIRATION_BY_ID_SUCCESS";
export const INSPIRATION_BY_ID_ERROR = "INSPIRATION_BY_ID_ERROR";

export const INSPIRATION_DELETE_REQUEST = "INSPIRATION_DELETE_REQUEST";
export const INSPIRATION_DELETE_SUCCESS = "INSPIRATION_DELETE_SUCCESS";
export const INSPIRATION_DELETE_ERROR = "INSPIRATION_DELETE_ERROR";

export const CAREER_REQUEST = "CAREER_REQUEST";
export const CAREER_SUCCESS = "CAREER_SUCCESS";
export const CAREER_ERROR = "CAREER_ERROR";

export const CAREER_META_REQUEST = "CAREER_META_REQUEST";
export const CAREER_META_SUCCESS = "CAREER_META_SUCCESS";
export const CAREER_META_ERROR = "CAREER_META_ERROR";

export const CAREER_BYID_REQUEST = "CAREER_BYID_REQUEST";
export const CAREER_BYID_SUCCESS = "CAREER_BYID_SUCCESS";
export const CAREER_BYID_ERROR = "CAREER_BYID_ERROR";

export const CAREER_LIKE_REQUEST = "CAREER_LIKE_REQUEST";
export const CAREER_LIKE_SUCCESS = "CAREER_LIKE_SUCCESS";
export const CAREER_LIKE_ERROR = "CAREER_LIKE_ERROR";

export const UNI_REQUEST = "UNI_REQUEST";
export const UNI_SUCCESS = "UNI_SUCCESS";
export const UNI_ERROR = "UNI_ERROR";
export const UNI_BYID_SUCCESS = "UNI_BYID_SUCCESS";

export const SUBJECT_REQUEST = "SUBJECT_REQUEST";
export const SUBJECT_SUCCESS = "SUBJECT_SUCCESS";
export const SUBJECT_ERROR = "SUBJECT_ERROR";

export const INDUSTRIES_REQUEST = "INDUSTRIES_REQUEST";
export const INDUSTRIES_SUCCESS = "INDUSTRIES_SUCCESS";
export const INDUSTRIES_ERROR = "INDUSTRIES_ERROR";

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_ERROR = "USERS_ERROR";

export const USERS_DELETE_REQUEST = "USERS_DELETE_REQUEST";
export const USERS_DELETE_SUCCESS = "USERS_DELETE_SUCCESS";
export const USERS_DELETE_ERROR = "USERS_DELETE_ERROR";

export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_ERROR = "CATEGORY_ERROR";
export const CATEGORY_BYID_SUCCESS = "CATEGORY_BYID_SUCCESS";

export const MEDIA_REQUEST = "MEDIA_REQUEST";
export const MEDIA_SUCCESS = "MEDIA_SUCCESS";
export const MEDIA_ERROR = "MEDIA_ERROR";

export const MAIL_REQUEST = "MAIL_REQUEST";
export const MAIL_SUCCESS = "MAIL_SUCCESS";
export const MAIL_ERROR = "MAIL_ERROR";

export const GRAPH_REQUEST = "GRAPH_REQUEST";
export const GRAPH_SUCCESS = "GRAPH_SUCCESS";
export const GRAPH_ERROR = "GRAPH_ERROR";

export const THEME_REQUEST = "THEME_REQUEST";

export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const SCHOLARSHIP_REQUEST = "SCHOLARSHIP_REQUEST";
export const SCHOLARSHIP_SUCCESS = "SCHOLARSHIP_SUCCESS";
export const SCHOLARSHIP_ERROR = "SCHOLARSHIP_ERROR";
export const SCHOLARSHIP_BY_ID_SUCCESS = "SCHOLARSHIP_BY_ID_SUCCESS";
