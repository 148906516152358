import {
  SCHOLARSHIP_BY_ID_SUCCESS,
  SCHOLARSHIP_ERROR,
  SCHOLARSHIP_REQUEST,
  SCHOLARSHIP_SUCCESS,
} from "src/constant/Types";
import { ScholarshipState } from "./Modules/scholarship";

const initialState: ScholarshipState = {
  loader: false,
  scholarship: [],
  scholarshipById: {},
  metaData: {},
  totalPage: 0,
  totalCount: 0,
};

const ScholarshipReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SCHOLARSHIP_REQUEST: {
      return Object.assign({}, state, {
        loader: true,
      });
    }
    case SCHOLARSHIP_BY_ID_SUCCESS: {
      return Object.assign({}, state, {
        scholarshipById: action.payload,
        loader: false,
      });
    }

    case SCHOLARSHIP_SUCCESS: {
      return Object.assign({}, state, {
        scholarship: [...action.payload?.data],
        metaData: action.payload.meta,
        totalPage: action.payload.meta?.lastPage,
        loader: false,
        totalCount: action.payload.meta?.totalCount,
      });
    }

    case SCHOLARSHIP_ERROR: {
      return Object.assign({}, state, {
        loader: false,
      });
    }

    default:
      return state;
  }
};

export default ScholarshipReducer;
